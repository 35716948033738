<template lang="pug">
.manager-selector
  validation-row(
    ref="refSelector"
    :required="required"
    :custom="custom"
    :asterisk="required"
    :not-empty="required"
    :watch-validation="required || custom"
    :label="label"
    :disabled="disabled"
    :model-value="modelValue"
    :custom-checker="customChecker"
    @error="$emit('error', 'manager')"
    @correct="$emit('correct', 'manager')"
  )
    template(#form-warning)
      span.set-me(v-if="!disabled && showSetMeLink" @click="selectMe") Назначить меня
    ui-select(
      clearable
      field="fio"
      placeholder="Выберите менеджера"
      :filterable="filterable"
      :model-value="innerModelValue"
      :options="managersList"
      :full-info="true"
      :disabled="disabled"
      :teleported="teleported"
      :persistent="persistent"
      @update:modelValue="selectManager($event)"
    )
  ui-checkbox(v-if="showSetMe" v-model="selectMeCheckboxValue" id="manager-selector-checkbox" label="Назначить меня" :disabled="disabled")
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import { storeToRefs } from "pinia";
import cloneDeep from "lodash/cloneDeep";
import useAuthStore from "@/stores/auth/useAuthStore";
import useUsersStore from "@/stores/users/useUsersStore";

import pick from "lodash/pick";
import ValidationRow from "@/components/ui/form/input/ValidationRow.vue";
import UiSelect from "@/components/ui/select/select/UiSelect.vue";
import UiCheckbox from "@/components/ui/checkbox/UiCheckbox.vue";

import type { ManagerI } from "@/stores/auth/UserInterface";
import useTmManagerStore from "@/stores/tenmonManager/useTmManagerStore";

export default defineComponent({
  name: "ManagerSelector",
  components: {
    UiCheckbox,
    ValidationRow,
    UiSelect,
  },
  emits: [
    'error',
    'correct',
    'update:modelValue',
    'setManager',
  ],
  props: {
    teleported: {
      type: Boolean,
      default: true,
    },
    persistent: {
      type: Boolean,
      default: true,
    },
    filterable: {
      type: Boolean,
      default: false,
    },
    isTmManager: {
      type: Boolean,
      default: false,
    },
    showSetMe: {
      type: Boolean,
      default: false,
    },
    showSetMeLink: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: [String, Number, Object]
    },
    label: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    custom: {
      type: Boolean,
      default: false,
    },
    customChecker: {
      type: Function,
      default: () => {},
    },
  },
  setup(props, context) {

    const authStore = useAuthStore();
    const { me } = storeToRefs(authStore);

    const usersStore = useUsersStore();
    const { activeUsers } = storeToRefs(usersStore);

    const managerStore = useTmManagerStore();
    const { activeAdmins } = storeToRefs(managerStore);

    const refSelector = ref();

    function validate() {
      refSelector.value.validate()
    }

    const managersList = computed<ManagerI[]>(() => props.isTmManager ? activeAdmins.value : activeUsers.value)

    function selectManager(element: any) {
      context.emit('update:modelValue', element)
      context.emit('setManager', { newValue: element, oldValue: cloneDeep(props.modelValue) })
    }

    const innerModelValue = computed(() => {
      if (props.modelValue && typeof props.modelValue === 'object') {
        return {
          label: [props.modelValue.surName, props.modelValue.firstName, props.modelValue.middleName].join(' '),
          ...props.modelValue,
        };
      }

      return props.modelValue;
    })

    const selectMeCheckboxValue = computed({
      get: () => props.modelValue?.id === me.value.id,
      set(value: boolean): void {
        selectMe(value)
      },
    })

    function selectMe(value: boolean) {
      selectManager(value ? pick(me.value, ['id', 'firstName', 'middleName', 'surName']) : null)
    }

    return {
      innerModelValue,
      managersList,
      selectMeCheckboxValue,
      refSelector,
      validate,      // для внешней компоненты
      selectManager,
      selectMe,
    }
  }
})
</script>

<style scoped lang="scss">
.manager-selector {
  display: flex;
  flex-flow: column;
  gap: 4px;

  min-width: 240px;
}

.set-me {
  color: var(--main-color-blue);
  cursor: pointer;
  margin-left: auto;
}
</style>
